import firstImage from "../assets/features/boarding/mobile/1.jpg";
import secondImage from "../assets/features/boarding/mobile/2.jpg";
import thirdImage from "../assets/features/boarding/mobile/3.jpg";
import fourthImage from "../assets/features/boarding/mobile/4.jpg";
import fifthImage from "../assets/features/boarding/mobile/5.jpg";
import twentyninthImage from "../assets/features/boarding/mobile/6.jpg";
import thirtiethImage from "../assets/features/boarding/mobile/7.jpg";
import thirtyfirstImage from "../assets/features/boarding/mobile/8.jpg";
import thirtysecondImage from "../assets/features/boarding/mobile/9.jpg";


import sixthImage from "../assets/features/powiis/mobile/1.jpg";
import seventhImage from "../assets/features/powiis/mobile/2.jpg";
import eighthImage from "../assets/features/powiis/mobile/3.jpg";
import ninthImage from "../assets/features/powiis/mobile/4.jpg";
import tenthImage from "../assets/features/powiis/mobile/5.jpg";
import eleventhImage from "../assets/features/powiis/mobile/6.jpg";
import twelfthImage from "../assets/features/powiis/mobile/7.jpg";
import thirteenthImage from "../assets/features/powiis/mobile/8.jpg";
import fourteenthImage from "../assets/features/powiis/mobile/9.jpg";

import fifteenthImage from "../assets/features/powiis-primary/mobile/1.jpg";
import sixteenthImage from "../assets/features/powiis-primary/mobile/2.jpg";
import seventeenthImage from "../assets/features/powiis-primary/mobile/3.jpg";
import eighteenthImage from "../assets/features/powiis-primary/mobile/4.JPG";
import nineteenthImage from "../assets/features/powiis-primary/mobile/5.jpg";
import twentiethImage from "../assets/features/powiis-primary/mobile/6.jpg";
import twentyfirstImage from "../assets/features/powiis-primary/mobile/7.jpg";
import twentysecondImage from "../assets/features/powiis-primary/mobile/8.jpg";
import twentythirdImage from "../assets/features/powiis-primary/mobile/9.jpg";
import twentyfourthImage from "../assets/features/powiis-primary/mobile/10.jpg";
import twentyfifthImage from "../assets/features/powiis-primary/mobile/11.jpg";

import twentysixthImage from "../assets/features/sixth-form/mobile/1.jpg";
import twentyseventhImage from "../assets/features/sixth-form/mobile/2.jpg";
import twentyeighthImage from "../assets/features/sixth-form/mobile/3.jpg";

export const boardingMobile = [
  {
    id: 0,
    src: firstImage,
  },
  {
    id: 1,
    src: secondImage,
  },
  {
    id: 2,
    src: thirdImage,
  },
  {
    id: 3,
    src: fourthImage,
  },
  {
    id: 4,
    src: fifthImage,
  },
  {
    id: 5,
    src: twentyninthImage,
  },
  {
    id: 6,
    src: thirtiethImage,
  },
  {
    id: 7,
    src: thirtyfirstImage,
  },
  {
    id: 8,
    src: thirtysecondImage,
  },
];

export const powiisMobile = [
  {
    id: 0,
    src: sixthImage,
  },
  {
    id: 1,
    src: seventhImage,
  },
  {
    id: 2,
    src: eighthImage,
  },
  {
    id: 3,
    src: ninthImage,
  },
  {
    id: 4,
    src: tenthImage,
  },
  {
    id: 5,
    src: eleventhImage,
  },
  {
    id: 6,
    src: twelfthImage,
  },
  {
    id: 7,
    src: thirteenthImage,
  },
  {
    id: 8,
    src: fourteenthImage,
  },
];

export const powiisPrimaryMobile = [
  {
    id: 0,
    src: fifteenthImage,
  },
  {
    id: 1,
    src: sixteenthImage,
  },
  {
    id: 2,
    src: seventeenthImage,
  },
  {
    id: 3,
    src: eighteenthImage,
  },
  {
    id: 4,
    src: nineteenthImage,
  },
  {
    id: 5,
    src: twentiethImage,
  },
  {
    id: 6,
    src: twentyfirstImage,
  },
  {
    id: 7,
    src: twentysecondImage,
  },
  {
    id: 8,
    src: twentythirdImage,
  },
  {
    id: 9,
    src: twentyfourthImage,
  },
  {
    id: 10,
    src: twentyfifthImage,
  },
];

export const sixthFormMobile = [
  {
    id: 0,
    src: twentysixthImage,
  },
  {
    id: 1,
    src: twentyseventhImage,
  },
  {
    id: 2,
    src: twentyeighthImage,
  },
];
