import firstImage from "../assets/features/boarding/1.jpg";
import secondImage from "../assets/features/boarding/2.jpg";
import thirdImage from "../assets/features/boarding/3.jpg";
import fourthImage from "../assets/features/boarding/4.jpg";
import fifthImage from "../assets/features/boarding/5.jpg";
import twentyninthImage from "../assets/features/boarding/6.jpg";
import thirtiethImage from "../assets/features/boarding/7.jpg";
import thirtyfirstImage from "../assets/features/boarding/8.jpg";
import thirtysecondImage from "../assets/features/boarding/9.jpg";

import sixthImage from "../assets/features/powiis/1.jpg";
import seventhImage from "../assets/features/powiis/2.jpg";
import eighthImage from "../assets/features/powiis/3.jpg";
import ninthImage from "../assets/features/powiis/4.jpg";
import tenthImage from "../assets/features/powiis/5.jpg";
import eleventhImage from "../assets/features/powiis/6.jpg";
import twelfthImage from "../assets/features/powiis/7.jpg";
import thirteenthImage from "../assets/features/powiis/8.jpg";
import fourteenthImage from "../assets/features/powiis/9.jpg";

import fifteenthImage from "../assets/features/powiis-primary/1.jpg";
import sixteenthImage from "../assets/features/powiis-primary/2.jpg";
import seventeenthImage from "../assets/features/powiis-primary/3.jpg";
import eighteenthImage from "../assets/features/powiis-primary/4.JPG";
import nineteenthImage from "../assets/features/powiis-primary/5.jpg";
import twentiethImage from "../assets/features/powiis-primary/6.jpg";
import twentyfirstImage from "../assets/features/powiis-primary/7.jpg";
import twentysecondImage from "../assets/features/powiis-primary/8.jpg";
import twentythirdImage from "../assets/features/powiis-primary/9.jpg";
import twentyfourthImage from "../assets/features/powiis-primary/10.jpg";
import twentyfifthImage from "../assets/features/powiis-primary/11.jpg";

import twentysixthImage from "../assets/features/sixth-form/1.jpg";
import twentyseventhImage from "../assets/features/sixth-form/2.jpg";
import twentyeighthImage from "../assets/features/sixth-form/3.jpg";

export const boarding = [
  {
    id: 0,
    src: firstImage,
  },
  {
    id: 1,
    src: secondImage,
  },
  {
    id: 2,
    src: thirdImage,
  },
  {
    id: 3,
    src: fourthImage,
  },
  {
    id: 4,
    src: fifthImage,
  },
  {
    id: 5,
    src: twentyninthImage,
  },
  {
    id: 6,
    src: thirtiethImage,
  },
  {
    id: 7,
    src: thirtyfirstImage,
  },
  {
    id: 8,
    src: thirtysecondImage,
  },
];

export const powiis = [
  {
    id: 0,
    src: sixthImage,
  },
  {
    id: 1,
    src: seventhImage,
  },
  {
    id: 2,
    src: eighthImage,
  },
  {
    id: 3,
    src: ninthImage,
  },
  {
    id: 4,
    src: tenthImage,
  },
  {
    id: 5,
    src: eleventhImage,
  },
  {
    id: 6,
    src: twelfthImage,
  },
  {
    id: 7,
    src: thirteenthImage,
  },
  {
    id: 8,
    src: fourteenthImage,
  },
];

export const powiisPrimary = [
  {
    id: 0,
    src: fifteenthImage,
  },
  {
    id: 1,
    src: sixteenthImage,
  },
  {
    id: 2,
    src: seventeenthImage,
  },
  {
    id: 3,
    src: eighteenthImage,
  },
  {
    id: 4,
    src: nineteenthImage,
  },
  {
    id: 5,
    src: twentiethImage,
  },
  {
    id: 6,
    src: twentyfirstImage,
  },
  {
    id: 7,
    src: twentysecondImage,
  },
  {
    id: 8,
    src: twentythirdImage,
  },
  {
    id: 9,
    src: twentyfourthImage,
  },
  {
    id: 10,
    src: twentyfifthImage,
  },
];

export const sixthForm = [
  {
    id: 0,
    src: twentysixthImage,
  },
  {
    id: 1,
    src: twentyseventhImage,
  },
  {
    id: 2,
    src: twentyeighthImage,
  },
];
