import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar";

const PrivacyNotice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-5xl py-10 mx-auto px-4 md:px-0">
      <h1 className="text-xl font-medium text-brand-primary text-center md:text-left">
        Privacy Notice
      </h1>
      <p className=" font-regular text-base text-black py-4   text-center md:text-left">
        The Prince of Wales Island International School respects your privacy
        and is committed to protect the personal information that you share with
        us. Generally you can browse through the School Website without giving
        us any personal information about yourself. Any data which you may
        volunteer from time to time on forms which collect information on this
        website is only used within the School, and is not divulged to any third
        party outside the School. When using your personal information The
        Prince of Wales Island International School will act in accordance with
        current legislation and aim to meet current Internet best practice.
      </p>
      <p className=" font-regular text-base text-black py-4  text-center md:text-left">
        The website automatically tracks accesses; however, it does not collect
        any personal information, but only the Internet address of the computer
        accessing the site, together with some information on the web browser
        that is being used on that computer, and a record of the pages visited
        on the site.
      </p>
      <p className=" font-regular text-base text-black py-4  text-center md:text-left">
        This policy may be amended from time to time. Any changes which affect
        the way that we use your personal information will be notified by
        posting a message on this site.
      </p>
      <p className=" font-regular text-base text-black py-4  text-center md:text-left">
        If you have any questions or concerns about our collection, use or
        disclosure of your personal information, please email us at{" "}
        <span
          className="text-brand-primary cursor-pointer"
          onClick={() => (window.location = "mailto:webmaster@powiis.edu.my.")}
        >
          webmaster@powiis.edu.my.
        </span>
      </p>
      <div className="flex justify-center md:justify-start">
        <Link
          to="/"
          className="mt-8 rounded-lg px-12 py-2 bg-brand-green text-white uppercase font-normal"
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export default PrivacyNotice;
