import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-footer-green">
      <div className="max-w-5xl py-10 mx-auto px-4 md:px-0">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start mb-10">
          <div className="flex flex-col">
            <h1 className="text-sm text-gray-800 mb-2 text-center md:text-left">
              Prince of Wales Island International School (Balik Pulau)
            </h1>
            <p className="text-sm font-light text-gray-800 mb-6 text-center md:text-left">
              1 Jalan Sungai Air Putih 6, Bandar Baru Air Putih <br />
              11000 Balik Pulau, Penang, Malaysia.<br />
              04 868 9999
            </p>
            <h1 className="text-sm text-gray-800 mb-2 text-center md:text-left">
              Prince of Wales Island International Primary School (Tanjung Bungah)
            </h1>
            <p className="text-sm font-light text-gray-800 text-center md:text-left">
              88 Jalan Loh Poh Heng <br />
              11200 Tanjung Bungah, Penang, Malaysia.<br />
              04 893 9999
            </p>
          </div>

          <div className="flex flex-col mt-10 md:mt-0 md:mr-40 ">
            <Link
              to="/privacy-notice"
              className="text-sm  text-gray-800 mb-4 text-center md:text-left font-normal"
            >
              Privacy Notice
            </Link>
            <Link
              to="/legal-notice"
              className="text-sm  text-gray-800 mb-4 text-center md:text-left font-normal"
            >
              Legal Notice
            </Link>
          </div>
        </div>
        <p className="text-xs font-light text-gray-800 text-center md:text-left px-4 md:px-0">
          Copyright © 2023 Prince of Wales Island International School Penang,
          Penang. All Rights Reserved. MTT Learning Academy Sdn. Bhd.
          200601013326 (733077-D)
        </p>
      </div>
    </div>
  );
};

export default Footer;
