import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

const LegalNotice = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-5xl py-10 mx-auto px-4 md:px-0">
      <h1 className="text-xl font-medium text-brand-primary text-center md:text-left">
        Legal Notice
      </h1>
      <p className=" font-regular text-base text-black py-4  text-center md:text-left">
        Our website and prospectus describe the broad principles on which the
        School is presently run and gives an indication of our plans and ethos.
        Although believed correct at the time of publication, they are not part
        of any agreement between Parents and the School. Parents wishing to
        place specific reliance on a matter contained in the prospectus or
        website or on a statement made by a member of staff during the course of
        a conducted tour of the School or related meeting, should seek written
        confirmation of that matter before making a commitment.
      </p>
      <div className="flex justify-center md:justify-start">
        <Link
          to="/"
          className="mt-8 rounded-lg px-12 py-2 bg-brand-green text-white uppercase font-normal"
        >
          Back
        </Link>
      </div>
    </div>
  );
};

export default LegalNotice;
