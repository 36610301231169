import React, { useEffect, useRef } from "react";
import About from "../../components/About";
import Features from "../../components/Features";
import Footer from "../../components/Footer";
import FormSection from "../../components/FormSection";
import Hero from "../../components/Hero";
import Navbar from "../../components/Navbar";
import WhyUs from "../../components/WhyUs";

const Landing = () => {
  const myRef = useRef(null);
  useEffect(() => {}, [myRef]);

  return (
    <div>
      <Hero />
      <FormSection myRef={myRef} />
      <Features myRef={myRef} />
      <About />
      <WhyUs />
    </div>
  );
};

export default Landing;
