import React, { useEffect, useState } from "react";

const FormSection = ({ myRef }) => {
  const [buttonText, setButtonText] = useState("REGISTER NOW");
  const [successText, setSuccessText] = useState("");
  const onClickHandler = (action, data) => {
    console.log("onclickcalled...features", action, data);
    switch (action) {
      case "register": {
        handleRegister(data);
        break;
      }
      default: {
        console.warn("case not handled...", action);
        break;
      }
    }
  };

  const handleRegister = async (formData) => {
    // make an API call to api: https://powiis-sendgrid-contact-form.cremedigital.workers.dev/ post request with formData as body field

    setButtonText("SENDING...");
    let res = await fetch(
      "https://powiis-sendgrid-contact-form.cremedigital.workers.dev/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );
    console.log("res...fetch", res);

    if (res.ok) {
      //  Submit GTM event
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "custom_form_submit",
        email: formData.email,
      });

      setButtonText("REGISTER NOW");
      setSuccessText("Form Submitted Successfully!");
    }
    // let data = await res.json();
    // console.log("fetch response...", data);
  };

  return (
    <div className="max-w-5xl py-10 mx-auto px-4 md:px-0">
      <h1 className="text-xl font-medium text-brand-primary text-center md:text-left">
      Unleash Your Potential at POWIIS
      </h1>
      <p className=" font-regular text-base text-black py-4  text-center md:text-left">
      Looking to unleash your true potential? Welcome to Prince of Wales Island International School Balik Pulau's Open Day where you will enjoy a welcome presentation by our Principal, Mrs Dinah Hawtree, speak to our Senior Leadership Team, take a tour of our school and our boarding facilities, and have all your questions answered in our Q&A session. Endless possibilities await at POWIIS, it’s time to unleash your true potential, register for our open day today!       </p>
      <p className="font-normal text-base text-brand-primary py-4  text-center md:text-left">
      Contact us here:
      </p>

      <div ref={myRef}>
        <FormGroup buttonText={buttonText} onClickHandler={onClickHandler} />
      </div>

      {successText && (
        <p className="text-brand-primary font-medium my-2">{successText}</p>
      )}
    </div>
  );
}; 

const FormGroup = ({ onClickHandler, buttonText }) => {
  const [formDefaults, setFormDefaults] = useState({
    childsName: "",
    parentsName: "",
    contactNumber: "",
    email: "",
    ageOfChild: "",
    currentSchool: "",
    message: "",
    date: {
      first: {
        label: "Open Day on 10th May 2024 (Friday)",
        checked: false,
      },
       second: {
        label: "Open Day on 11th May 2024 (Saturday)",
        checked: false,
      }
    },
    programmes: {
      first: {
        label: "POWIIS Boarding (For ages 11-19)",
        checked: false,
      },
      // first: {
      //   label: "Enquiry for A Level Programme (Ages 16-19)",
      //   checked: false,
      // },
      second:{
        label: "POWIIS Arts Academy (For ages 11-19)",
        checked: false,
      },
      third: {
        label: "POWIIS A Level (For ages 16-19)",
        checked: false,
      },
      fourth: {
        label: "General Enquiry for POWIIS Balik Pulau (Ages 11-19)",
        checked: false,
      },
      fifth: {
           label: "General Enquiry for POWIIS Tanjung Bungah (Ages 3-13)",
           checked: false,
      }

      // first: {
      //   label: "Sign up for Virtual Boarding Talk",
      //   checked: false,
      // },
      // second: {
      //   label: "General Enquiry for POWIIS Secondary (Ages 11-18)",
      //   checked: false,
      // },
      
      // fourth: {
      //   label: "POWIIS Boarding",
      //   checked: false,
      // },
      // fifth: {
      //   label: "Register for Open Day 11 Feb (Ages 11-18)",
      //   checked: false,
      // },
    },
  });
  const [error, setError] = useState(null);
  const [formDetails, setFormDetails] = useState({
    childsName: "",
    parentsName: "",
    contactNumber: "",
    email: "",
    currentSchool: "",
    message: "",
    ageOfChild: "",
    date: {
      first: {
        label: "Open Day on 10th May 2024 (Friday)",
        checked: false,
      },
       second: {
        label: "Open Day on 11th May 2024 (Saturday)",
        checked: false,
      }
    },
    programmes: {
      first: {
        label: "POWIIS Boarding (For ages 11-19)",
        checked: false,
      },
      // first: {
      //   label: "Enquiry for A Level Programme (Ages 16-19)",
      //   checked: false,
      // },
      second:{
         label: "POWIIS Arts Academy (For ages 11-19)",
         checked: false,
      },
      third: {
        label: "POWIIS A Level (For ages 16-19)",
        checked: false,
      },
      fourth: {
        label: "General Enquiry for POWIIS Balik Pulau (Ages 11-19)",
        checked: false,
      },
      fifth: {
           label: "General Enquiry for POWIIS Tanjung Bungah (Ages 3-13)",
           checked: false,
         },
      // first: {
      //   label: "Sign up for Virtual Boarding Talk",
      //   checked: false,
      // },
      // second: {
      //   label: "General Enquiry for POWIIS Secondary (Ages 11-18)",
      //   checked: false,
      // },
      
      // fourth: {
      //   label: "POWIIS Boarding",
      //   checked: false,
      // },
      // fifth: {
      //   label: "Register for Open Day (17th May - 20th May)",
      //   checked: false,
      // },
    },
  });
  const handleValidation = () => {
    const {
      childsName,
      parentsName,
      contactNumber,
      email,
      currentSchool,
      message,
      ageOfChild,
      date,
      programmes,
    } = formDetails;
    setError(null);

    if (
      !childsName ||
      !parentsName ||
      !contactNumber ||
      !email ||
      !currentSchool ||
      !ageOfChild
    ) {
      setError("Please fill all the required fields");
      return;
    }

    // check for correct email validation
    if (!validateEmail(email)) {
      setError("Please enter a valid email address");
      return;
    }
    if (
      !date.first.checked &&
      !date.second.checked
  ) {
      setError("Please select one date");
      return;
  } else if (
      date.first.checked &&
      date.second.checked
  ) {
      setError("Please select only one date");
      return;
  }

    if (
      !programmes.first.checked &&
      !programmes.second.checked &&
      !programmes.third.checked &&
      !programmes.fourth.checked &&
      !programmes.fifth.checked
    ) {
      setError("Please select atleast one programme");
      return;
    }

    // everything is good here, form the data and call the api
    const pg = [];

    Object.keys(date).forEach(key => {
      if (date[key].checked) {
        pg.push(date[key].label); 
      }
    });
    
    Object.keys(programmes).forEach(key => {
      if (programmes[key].checked) {
        pg.push(programmes[key].label); 
      }
    });
    
    const data = {
      childsName,
      parentsName,
      contactNumber,
      email,
      currentSchool,
      message,
      ageOfChild,
      date: pg.join("\n"), 
      programmes: pg.join("\n"),
    };

    console.log("data...", data);
    setFormDetails(formDefaults);

    onClickHandler("register", data);
  };
  const validateEmail = (email) => {
    // eslint-disable-next-line max-len
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const onChange = (field, value) => {
    setFormDetails({ ...formDetails, [field]: value });
  };

  const setDate = (field, value) => {
    setFormDetails({
      ...formDetails,
      date: {
        ...formDetails.date,
        [field]: {
          ...formDetails.date[field],
          checked: value,
        },
      },
    });
  };
  const setProgrammes = (field, value) => {
    setFormDetails({
      ...formDetails,
      programmes: {
        ...formDetails.programmes,
        [field]: {
          ...formDetails.programmes[field],
          checked: value,
        },
      },
    });
  };

  return (
    <>
      <div className="grid md:grid-cols-2 gap-x-10">
        <div className="flex flex-col order-1">
          <label
            htmlFor="parentsName"
            className="font-regular text-base text-black py-4"
          >
            Parent's Name*
          </label>
          <input
            type="text"
            id="parentsName"
            value={formDetails.parentsName}
            onChange={(e) => onChange("parentsName", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0  border-none "
          />
        </div>
        <div className="flex flex-col order-3">
          <label
            htmlFor="email"
            className="font-regular text-base text-black py-4"
          >
            Email*
          </label>
          <input
            type="email"
            id="email"
            value={formDetails.email}
            onChange={(e) => onChange("email", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          />
        </div>
        <div className="flex flex-col order-5">
          <label
            htmlFor="ageOfChild"
            className="font-regular text-base text-black py-4"
          >
            Child's date of birth*
          </label>
          <input
            type="date"
            id="ageOfChild"
            value={formDetails.ageOfChild}
            onChange={(e) => onChange("ageOfChild", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          />
        </div>
        <div className="flex flex-col order-7">
          <label
            htmlFor="message"
            className="font-regular text-base text-black py-4"
          >
            Message
          </label>
          <textarea
            type="number"
            id="message"
            rows="5"
            value={formDetails.message}
            onChange={(e) => onChange("message", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          ></textarea>
        </div>
        <div className="flex flex-col order-2">
          <label
            htmlFor="contactNumber"
            className="font-regular text-base text-black py-4"
          >
            Contact Number*
          </label>
          <input
            type="number"
            id="contactNumber"
            value={formDetails.contactNumber}
            onChange={(e) => onChange("contactNumber", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          />
        </div>
        <div className="flex flex-col order-4">
          <label
            htmlFor="childName"
            className="font-regular text-base text-black py-4"
          >
            Child's Name*
          </label>
          <input
            type="text"
            id="childName"
            value={formDetails.childsName}
            onChange={(e) => onChange("childsName", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          />
        </div>
        <div className="flex flex-col order-6">
          <label
            htmlFor="currentSchool"
            className="font-regular text-base text-black py-4"
          >
            Which school are you currently studying in?*
          </label>
          <input
            type="text"
            id="currentSchool"
            value={formDetails.currentSchool}
            onChange={(e) => onChange("currentSchool", e.target.value)}
            className="p-2 bg-[#EEF5E0] focus:ring-0 border-none"
          />
        </div>

        <div className="order-8">
  <p className="font-regular text-base text-black py-4">
    Which day are you attending?
    <br />

  </p>
  <div className="flex flex-col">
    <div className="flex flex-row items-center mb-1">
      <input
        type="checkbox"
        name="first"
        id="first"
        checked={formDetails.date.first.checked}
        onChange={(e) => setDate("first", e.target.checked)}
        className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0] bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
      />
      <div className="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
        <svg
          className={`fill-current ${
            formDetails.date.first.checked ? "block" : "hidden"
          } w-3 h-3 text-blue-600 pointer-events-none`}
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="black" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <label htmlFor="first" className="font-regular text-base text-black">
        Open Day on 10th May 2024 (Friday)
      </label>
    </div>

    <div className="flex flex-row items-center mb-1">
      <input
        type="checkbox"
        name="second"
        id="second"
        checked={formDetails.date.second.checked}
        onChange={(e) => setDate("second", e.target.checked)}
        className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0] bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
      />
      <div className="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
        <svg
          className={`fill-current ${
            formDetails.date.second.checked ? "block" : "hidden"
          } w-3 h-3 text-blue-600 pointer-events-none`}
          version="1.1"
          viewBox="0 0 17 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-9 -11)" fill="black" fillRule="nonzero">
              <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
            </g>
          </g>
        </svg>
      </div>
      <label htmlFor="second" className="font-regular text-base text-black">
        Open Day on 11th May 2024 (Saturday)
      </label>
    </div>
  </div>
</div>

        <div className="order-8">
          <p className="font-regular text-base text-black py-4">
            Which programme are you interested in?
            <br />
            (You can choose more than 1 option)*
          </p>
          <div className="flex flex-col">
          {<div className="flex flex-row items-center mb-1">
              <input
                type="checkbox"
                name="first"
                id="first"
                checked={formDetails.programmes.first.checked}
                onChange={(e) => setProgrammes("first", e.target.checked)}
                className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0]  bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
              />
              <div class="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <svg
                  class="fill-current hidden w-3 h-3 text-blue-600 pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="black"
                      fill-rule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor="first"
                className="font-regular text-base text-black"
              >
               POWIIS Boarding (For ages 11-19)
               {/* Enquiry for A Level Programme (Ages 16-19) */}
              </label>
            </div>}

            <div className="flex flex-row items-center mb-1">
              <input
                type="checkbox"
                name="second"
                id="second"
                checked={formDetails.programmes.second.checked}
                onChange={(e) => setProgrammes("second", e.target.checked)}
                className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0]  bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
              />
              <div class="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <svg
                  class="fill-current hidden w-3 h-3 text-blue-600 pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="black"
                      fill-rule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor="second"
                className="font-regular text-base text-black"
              >
                
                POWIIS Arts Academy (For ages 11-19)
              </label>
            </div>
            <div className="flex flex-row items-center mb-1">
              <input
                type="checkbox"
                name="third"
                id="third"
                checked={formDetails.programmes.third.checked}
                onChange={(e) => setProgrammes("third", e.target.checked)}
                className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0]  bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
              />
              <div class="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <svg
                  class="fill-current hidden w-3 h-3 text-blue-600 pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="black"
                      fill-rule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor="third"
                className="font-regular text-base text-black"
              >

              POWIIS A Level (For ages 16-19)
              </label>
            </div>
            <div className="flex flex-row items-center mb-1">
              <input
                type="checkbox"
                name="fourth"
                id="fourth"
                checked={formDetails.programmes.fourth.checked}
                onChange={(e) => setProgrammes("fourth", e.target.checked)}
                className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0]  bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
              />
              <div class="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <svg
                  class="fill-current hidden w-3 h-3 text-blue-600 pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="black"
                      fill-rule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor="fourth"
                className="font-regular text-base text-black"
              >

                General Enquiry for POWIIS Balik Pulau (Ages 11-19) 
                </label>
            </div>
            <div className="flex flex-row items-center mb-1">
              <input
                type="checkbox"
                name="fifth"
                id="fifth"
                checked={formDetails.programmes.fifth.checked}
                onChange={(e) => setProgrammes("fifth", e.target.checked)}
                className="opacity-0 absolute h-5 w-5 mr-2 cursor-pointer checked:bg-[#EEF5E0] checked:hover:bg-[#EEF5E0]  bg-[#EEF5E0] focus:outline-none active:checked:bg-[#EEF5E0] focus:ring-0 border-none text-black"
              />
              <div class="bg-[#EEF5E0] w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <svg
                  class="fill-current hidden w-3 h-3 text-blue-600 pointer-events-none"
                  version="1.1"
                  viewBox="0 0 17 12"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g
                      transform="translate(-9 -11)"
                      fill="black"
                      fill-rule="nonzero"
                    >
                      <path d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z" />
                    </g>
                  </g>
                </svg>
              </div>
              <label
                htmlFor="fifth"
                className="font-regular text-base text-black"
          >

                General Enquiry for POWIIS Tanjung Bungah (Ages 3-13)
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-start md:justify-start">
        <div className="h-6 mt-8">
          {error && <p className="font-medium text-red-500">{error}</p>}
        </div>
        <div className="flex justify-center md:justify-start w-full mb-8">
          <button
            onClick={handleValidation}
            className="rounded-lg  bg-brand-yellow text-brand-green-secondary font-medium uppercase mt-2 h-[2.5rem] w-[12rem]"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

export default FormSection;
