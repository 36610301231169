import React from "react";
import styles from "./About.module.css";

const About = () => {
  return (
    <div className="bg-background-blue">
      <div className="max-w-5xl py-10 mx-auto">
        <h1 className="text-xl font-medium  text-brand-primary text-center md:text-left">
          About the POWIIS Schools
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 px-4 md:px-0">
          <div>
            <p className=" font-regular text-base text-black py-4 text-center md:text-left">
            We offer a broad and balanced education based on a British-style curriculum for 
            students aged 3 to 19. Here on Penang Island, we have two unique campuses. 
            One in Tanjung Bungah that caters to students aged 3 to 13 and the other 
            in Balik Pulau, with boarding facilities, for students aged 11 to 19. 
            Our teaching staff are highly qualified and bring a wealth of experience. 
            </p>
          </div>
          <div>
            <div className={styles.videoContainer}>
              <iframe
                className={`${styles.video}`}
                src="https://www.youtube.com/embed/AYKdcW_on58"
                // src="https://www.youtube.com/embed/SPiw9B_0eO4"
                title="YouTube video player"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
