import React from "react";

import first from "../../assets/whyus/1.png";
import second from "../../assets/whyus/2.png";
import third from "../../assets/whyus/3.png";
import fourth from "../../assets/whyus/4.png";
import fifth from "../../assets/whyus/5.png";
import sixth from "../../assets/whyus/6.png";

const WhyUs = () => {
  return (
    <div className="max-w-5xl py-20 mx-auto">
      <h1 className="text-xl font-medium  text-brand-primary text-center md:text-left my-4">
        Why Penang
      </h1>
      <p className=" font-regular text-base text-black file:py-4 text-center md:text-left px-4">
        Penang is a state in the northwest of Malaysia has always been well
        known as a travel destination due to its unique offerings such as
        beautiful scenery, rich cultural heritage, delicious foods and state of
        the art medical facilities. Those who have chosen to relocate to Penang
        because it offers them an ideal environment to bring up their children.
      </p>

      <div className="grid grid-cols-2 md:grid-cols-3 my-10 gap-6 gap-y-20 mx-auto px-8 md:px-0">
        <img src={first} alt="first" className="w-72 h-auto mx-auto md:mx-0" />
        <img
          src={second}
          alt="second"
          className="w-72 h-auto mx-auto md:mx-0"
        />
        <img src={third} alt="third" className="w-72 h-auto mx-auto md:mx-0" />
        <img
          src={fourth}
          alt="fourth"
          className="w-64 h-auto mx-auto md:mx-0"
        />
        <img src={fifth} alt="fifth" className="w-72 h-auto mx-auto md:mx-0" />
        <img src={sixth} alt="sixth" className="w-72 h-auto mx-auto md:mx-0" />
      </div>
    </div>
  );
};

export default WhyUs;
