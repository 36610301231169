import React, { useEffect, useState } from "react";
import LightboxModal from "../LightboxModal";

import {
  boarding,
  powiis,
  powiisPrimary,
  sixthForm,
} from "../../data/webImages";

import { videos } from "../../data/videos";
import { useMediaQuery } from "react-responsive";
import {
  boardingMobile,
  powiisMobile,
  powiisPrimaryMobile,
  sixthFormMobile,
} from "../../data/mobileImages";

import powiisThumbnail from "../../assets/features/thumbnails/powiis.png";
import powiisPrimaryThumbnail from "../../assets/features/thumbnails/powiis-primary.png";
import boardingThumbnail from "../../assets/features/thumbnails/boarding_optimised.jpg";
import sixthFormThumbnail from "../../assets/features/thumbnails/sixth-form.png";

const Features = ({ myRef }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const featuresData = [
    {
      title: "POWIIS Balik Pulau",
      description:
        "The Prince of Wales Island International School (POWIIS) opened in 2011 and has rapidly established a local, national and international reputation as one of Malaysia’s most significant British Secondary schools. Located on the island of Penang in Georgetown, POWIIS offers a broad and balanced education based on the English National Curriculum for students aged 18 to 19 which will prepare them for IGCSE and A Levels. With a purpose-built campus with boarding houses, offering high quality teaching and outstanding facilities ensures every child has a sure pathway to success.",
      callToActionText: "Get in touch to speak to our admissions team.",
      imageUrl: isMobile ? powiisMobile[0] : powiis[0],
      videoUrl: videos[0],
      imageCaption: "Photo Gallery",
      id: "powiis",
      thumbnail: powiisThumbnail,
      idName: "powiis",
    },
    {
      title: "POWIIS Tanjung Bungah",
      description:
        "An International Middle school offering the English National Curriculum to children aged 11 to 12, located in the northern region of the Penang island in Georgetown. Our rich, broad curriculum, with its specialist programmes in Mandarin, sport, technology and the performing and creative arts will help develop articulate, confident, well-rounded children prepared for the ever-changing world they live in.",
      callToActionText: "Get in touch to speak to our admissions team.",
      imageUrl: isMobile ? powiisPrimaryMobile[0] : powiisPrimary[0],
      videoUrl: videos[1],
      imageCaption: "Photo Gallery",
      id: "powiis-primary",
      thumbnail: powiisPrimaryThumbnail,
      idName: "primary",
    },
    {
      title: "Boarding at POWIIS",
      description:
        "“An adventure for the mind and a home for the heart” - A British style boarding school situated on the island of Penang, Malaysia. Our residential boarding houses are located within the school campus with 24hr security. At boarding, we provide an opportunity for students to develop character and learn to be independent. In addition, they are able to be socially confident by building friendships and making connections with students from diverse cultural backgrounds. Lastly, the students are nurtured to reach their academic potential, especially during examination preparation for IGCSE and A Levels with supervised study time as part of the programme.",
      callToActionText: "Get in touch to speak to our admissions team.",
      imageUrl: isMobile ? boardingMobile[0] : boarding[0],
      imageCaption: "Photo Gallery",
      videoUrl: videos[2],
      id: "boarding",
      thumbnail: boardingThumbnail,
      idName: "boarding",
    },
    {
      title: "A Levels at POWIIS",
      description:
        "POWIIS offers a 2 year A Level programme for students aged 16 to 19. This selected, closely knitted community which we call Sixth Form is almost a school within a school, with a dedicated common room and an exclusive, silent study area just for the students. The Sixth Form students not only benefit from having the academic rigour, at the same time, our rich and broad extracurricular curriculum with leadership offerings will grant them an advantage while applying for universities. With the support from the school’s university and career guidance counsellor, every student is guided through their university applications throughout his journey at POWIIS.",
      callToActionText: "Get in touch to speak to our admissions team.",
      imageUrl: isMobile ? sixthFormMobile[0] : sixthForm[0],
      imageCaption: "Photo Gallery",
      videoUrl: videos[3],
      id: "sixth-form",
      thumbnail: sixthFormThumbnail,
      idName: "alevels",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type, setType] = useState("image");
  const [selectedImagesArray, setSelectedImagesArray] = useState(boarding);
  const [selectedEntity, setSelectedEntity] = useState(false);

  useEffect(() => {
    console.log("isMobile...", isMobile);
  }, [isMobile]);

  const onClick = (action, data) => {
    console.log("onclickcalled...features", action, data);
    switch (action) {
      case "image-click": {
        setIsModalOpen(true);
        setSelectedEntity({ ...data.imageUrl });
        setType("image");
        const newImagesArray = getImagesArray(data.id);
        console.log("newImagesArray...", newImagesArray);
        setSelectedImagesArray([...newImagesArray]);
        break;
      }
      case "video-click": {
        setIsModalOpen(true);
        setSelectedEntity({ ...data });
        setType("video");
        break;
      }
      case "closeModal": {
        setIsModalOpen(false);
        break;
      }
      default: {
        console.warn("case not handled...");
        break;
      }
    }
    setIsModalOpen(true);
  };
  const getImagesArray = (id) => {
    console.log("action...", id);
    switch (id) {
      case "powiis": {
        return isMobile ? powiisMobile : powiis;
      }
      case "powiis-primary": {
        return isMobile ? powiisPrimaryMobile : powiisPrimary;
      }
      case "boarding": {
        return isMobile ? boardingMobile : boarding;
      }
      case "sixth-form": {
        return isMobile ? sixthFormMobile : sixthForm;
      }
      default: {
        console.warn("case not handled...");
        break;
      }
    }
  };
  return (
    <>
      <LightboxModal
        isModalOpen={isModalOpen}
        selectedEntity={selectedEntity}
        type={type}
        imagesArray={selectedImagesArray}
        onclose={() => onClick("closeModal")}
      />
      <div className="max-w-[68rem] py-10 mx-auto">
        {featuresData.map((features, idx) => (
          <FeaturesCard
            myRef={myRef}
            key={idx}
            features={features}
            onClick={onClick}
          />
        ))}
      </div>
    </>
  );
};

const FeaturesCard = ({ features, onClick, myRef }) => {
  const executeScroll = () => {
    myRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };
  return (
    <div
      id={features.idName}
      className="relative border border-brand-green-secondary rounded-xl md:rounded-lg mb-20 md:mb-20 mx-10 md:mx-0 px-8 py-6"
    >
      <div className="relative w-44 md:w-72 rounded-2xl md:hidden  overflow-hidden -mt-20 mx-auto mb-10">
        <div
          onClick={() => onClick("video-click", features.videoUrl)}
          className="absolute w-12 h-12 flex flex-row items-center justify-center bg-white/70 z-10 top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="transform rotate-90 h-6 w-6 ml-2 text-brand-dark-green"
          >
            <path d="M24 22h-24l12-20z" />
          </svg>
        </div>
        <img
          src={features.thumbnail}
          alt="U"
          className="h-32 w-96 object-cover"
          onClick={() => onClick("video-click", features.videoUrl)}
        />

        <div
          onClick={() => onClick("image-click", features)}
          className="bg-brand-dark-green text-white font-medium text-center py-3 uppercase"
        >
          {features.imageCaption}
        </div>
      </div>
      <div className="flex flex-row justify-between items-start">
        <div className="md:max-w-lg lg:max-w-2xl md:mt-0 ">
          <h1 className="text-xl font-medium  text-brand-primary text-center md:text-left">
            {features.title}
          </h1>
          <p className=" font-regular text-sm md:text-base text-black py-4 text-center md:text-left">
            {features.description}
          </p>
          <p className="text-sm md:text-base font-regular text-black text-center md:text-left">
            {features.callToActionText}
          </p>
        </div>
        <div className="min-h-32 w-44 md:w-72 rounded-2xl hidden md:block  overflow-hidden z-2 cursor-pointer -mt-20 relative ">
          <div
            onClick={() => onClick("video-click", features.videoUrl)}
            className="absolute w-24 h-24 flex flex-row items-center justify-center bg-white/70 z-10 top-[45%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-full border-4 border-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="transform rotate-90 h-12 w-12 ml-4 text-brand-dark-green"
            >
              <path d="M24 22h-24l12-20z" />
            </svg>
          </div>
          <img
            src={features.thumbnail}
            alt="U"
            className="transform h-64 w-96 object-cover"
            onClick={() => onClick("video-click", features.videoUrl)}
          />

          <div
            onClick={() => onClick("image-click", features)}
            className="bg-brand-dark-green text-white text-center py-3 uppercase rounded-b-2xl font-medium "
          >
            {features.imageCaption}
          </div>
        </div>
      </div>
      <div className="flex justify-center md:justify-start">
        <button
          onClick={executeScroll}
          className="rounded-lg bg-brand-yellow text-brand-green-secondary font-medium uppercase mt-8 h-[2.5rem] w-[12rem]"
        >
          Enquire Now
        </button>
      </div>
    </div>
  );
};

export default Features;
