// NOTE: has to be embeddable URL in order to display it in the Iframe
export const videos = [
  {
    id: 0,
    src: "https://youtube.com/embed/MCEPwp_QHEo",
  },
  {
    id: 1,
    src: "https://youtube.com/embed/YQ0s5dihDZ4",
  },
  {
    id: 2,
    src: "https://youtube.com/embed/fW2Eul6IJLU",
  },
  {
    id: 3,
    src: "https://youtube.com/embed/SPiw9B_0eO4",
  },
];
