import logo from "./logo.svg";
import "./App.css";
import Landing from "./pages/Landing";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivacyNotice from "./pages/PrivacyNotice";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LegalNotice from "./pages/LegalNotice";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/privacy-notice" component={PrivacyNotice} />
          <Route exact path="/legal-notice" component={LegalNotice} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
