import React from "react";
import header from "../../assets/banner_images/POW_ODAY_Landing_2405_V01_FA_Desktop.jpg";
import headerMobile from "../../assets/banner_images/POW_ODAY_Landing_2405_V01_FA_Mobile.jpg";
import styles from "./Hero.module.css";

const Hero = () => {
  return (
    <div className={` mx-auto customBackground ${styles.customBackground}`}>
      <div className="md:hidden max-w-[1450px] mx-auto">
        <img src={headerMobile} alt="header" className="" />
      </div>
      <div className="hidden md:block max-w-[1450px] mx-auto">
        <img src={header} alt="header" className="" />
      </div>
    </div>
  );
};

export default Hero;
