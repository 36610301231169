import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/static_logo.png";

const Navbar = () => {
  return (
    <nav className="max-w-5xl mx-auto py-8">
      <Link to="/">
        <img src={logo} alt="Logo" className="w-72 mx-auto md:mx-0" />
      </Link>
    </nav>
  );
};

export default Navbar;
