import React, { useEffect, useState } from "react";
import thirdImage from "../../assets/primary-new.png";
import {
  boarding,
  powiis,
  powiisPrimary,
  sixthForm,
} from "../../data/webImages";
import { videos } from "../../data/videos";
import styles from "./LightboxModal.module.css";

const LightboxModal = ({
  isModalOpen = false,
  selectedEntity,
  onclose,
  type = "image",
  imagesArray = boarding,
}) => {
  const [isOpen, setIsOpen] = useState(isModalOpen);
  const [data, setData] = useState(type === "image" ? imagesArray : videos);
  const [currentElement, setCurrentElement] = useState(
    selectedEntity
      ? data.find((elem) => elem.id === selectedEntity.id)
      : data[0]
  );

  useEffect(() => {
    setIsOpen(isModalOpen);
    setCurrentElement(selectedEntity);
    return () => {
      console.log("unmounted...");
    };
  }, [isModalOpen, selectedEntity]);

  useEffect(() => {
    console.log("imagesArray...", imagesArray);
    setData(imagesArray);
  }, [imagesArray]);

  useEffect(() => {
    setData(type === "image" ? imagesArray : videos);
  }, [type]);

  const onClick = (action) => {
    switch (action) {
      case "leftClick": {
        console.log("left click...");
        let previousId = currentElement.id - 1;
        if (previousId < 0) {
          previousId = data.length - 1;
        }
        setCurrentElement(data[previousId]);

        break;
      }
      case "rightClick": {
        console.log("right click...");
        let nextId = currentElement.id + 1;
        if (nextId > data.length - 1) {
          nextId = 0;
        }
        setCurrentElement(data[nextId]);
        break;
      }
      case "closeModal": {
        console.log("close modal...");
        onclose();
        setIsOpen(false);
        break;
      }
      default: {
        console.warn("case not handled...");
        break;
      }
    }
  };
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-30 h-screen overflow-hidden">
          {type === "image" ? (
            <img
              src={currentElement.src}
              alt="U"
              className="object-contain  max-w-[90%] max-h-[60%]"
            />
          ) : (
            <div className={styles.videoContainer}>
              <iframe
                className={`absolute top-1/2 left-1/2 h-[100%] w-[100%] md:h-[76%] md:w-[76%] border-none transform -translate-x-1/2 -translate-y-1/2 z-20`}
                src={currentElement.src}
                title="YouTube video player"
              ></iframe>
            </div>
          )}
          {type !== "video" ? (
            <>
              <div className="absolute left-10 top-1/2 z-20">
                <LeftIcon onClick={() => onClick("leftClick")} />
              </div>
              <div className="absolute right-10 top-1/2 z-20">
                <RightIcon onClick={() => onClick("rightClick")} />
              </div>
            </>
          ) : null}
          <div className="absolute top-10 right-10 z-20">
            <CloseIcon onClick={() => onClick("closeModal")} />
          </div>
        </div>
      )}
    </>
  );
};

const LeftIcon = ({ onClick, classList = "" }) => {
  return (
    <button onClick={onClick} className={classList}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#fff"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
        />
      </svg>
    </button>
  );
};
const RightIcon = ({ onClick, classList }) => {
  return (
    <button onClick={onClick} className={classList}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#fff"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </button>
  );
};
const CloseIcon = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-10 w-10"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#fff"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    </button>
  );
};

export default LightboxModal;
